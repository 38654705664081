<template>
  <div>
    <WmtHeader :activeKey='activeKey'></WmtHeader>
    <div id="singleModule" style="height:auto;">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/aboutUs/topBg.png" alt="" class="topbg">
    </div>

    <div id="singleModule" style="background: #f8f8f8;height:100px;">
      <div class="moduleContainer" style="padding-top:47px;">
        <div class="title">
          <!-- <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/liveIcon.png" alt="icon" class="icon"> -->
          <h3 class="title_content">精选案例</h3>
        </div>

        <!-- 样例列表 -->

      </div>
    </div>
    <CompanyHj :title="'华欣家园'" :imgkeyArr="hxjy"></CompanyHj>
    <CompanyHj :title="'绿林路320弄51号501'" :imgkeyArr="lvlin"></CompanyHj>
    <CompanyHj :title="'平吉路'" :imgkeyArr="pinji"></CompanyHj>
    <CompanyHj :title="'瑞近南苑'" :imgkeyArr=" ruijin"></CompanyHj>

    <!-- 底部通栏 -->
    <WmtFooter></WmtFooter>
  </div>
</template>

<script>
import WmtHeader from '@/components/layout/wmtHeader.vue'
import WmtFooter from '@/components/layout/wmtFooter.vue'
import CompanyHj from './companyHj.vue'
export default {
  name: 'Home',
  components: {
    WmtHeader,
    WmtFooter,
    CompanyHj,
  },
  data() {
    return {
      activeKey: 'live',
      hxjy: [
        {
          key: 'hxjy1',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy1.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy2.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy3.jpg'],
        },
        {
          key: 'hxjy2',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy4.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy5.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy6.jpg'],
        },
        {
          key: 'hxjy3',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy7.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy8.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy9.jpg'],
        },
        {
          key: 'hxjy4',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy10.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy11.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy12.jpg'],
        },
        {
          key: 'hxjy5',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy13.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy14.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy15.jpg'],
        },
        {
          key: 'hxjy6',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy16.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/hxjy17.jpg'],
        },
      ],
     
      lvlin: [
        {
          key: 'lvlin1',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin1.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin2.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin3.jpg'],
        },
        {
          key: 'lvlin2',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin4.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin5.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin6.jpg'],
        },
        {
          key: 'lvlin3',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin7.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin8.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/lvlin9.jpg'],
        },
      ], 
      pinji: [
        {
          key: 'pinji1',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji1.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji2.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji3.jpg'],
        },
        {
          key: 'pinji2',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji4.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji5.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji6.jpg'],
        },
        {
          key: 'pinji3',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji7.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji8.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji9.jpg'],
        },
        {
          key: 'pinji4',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji10.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji11.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji12.jpg'],
        },
        {
          key: 'pinji6',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji12.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/pinji13.jpg'],
        },
      ],
      ruijin: [
        {
          key: 'ruijin1',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin1.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin2.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin3.jpg'],
        },
        {
          key: 'ruijin2',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin4.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin5.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin6.jpg'],
        },
        {
          key: 'ruijin3',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin7.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin8.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin9.jpg'],
        },
        {
          key: 'ruijin4',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin10.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin11.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin12.jpg'],
        },
        {
          key: 'ruijin5',
          value: ['https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin13.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin14.jpg', 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/ruijin15.jpg'],
        },
      ],
    }
  },
  methods: {
    goDetail(e) {
      this.$router.push({ path: '/liveDetail', query: { id: e.id } })
    },
  },
}
</script>

<style lang="less" scoped>
.topbg {
  width: 100%;
  height: 210px;
}

.title {
  display: flex;
  .icon {
    width: 33px;
    height: 33px;
  }
  .title_content {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-left: 10px;
  }
}

.anchorList {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 53px;
  cursor: pointer;
  .anchorItem {
    width: 231px;
    height: 286px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    .playBtn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #ffffff;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      line-height: 60px;
      opacity: 0;
      .iconfont {
        color: #5abec1;
        font-size: 20px;
      }
    }
    &:hover {
      background: #5abec1;
      .playBtn {
        opacity: 1;
      }
      .img {
        webkit-transform: scale(0.97);
        -moz-transform: scale(0.97);
        -ms-transform: scale(0.97);
        -o-transform: scale(0.97);
        transform: scale(0.97);
        -webkit-transition: transform 1s linear;
        -moz-transition: transform 1s linear;
        -ms-transition: transform 1s linear;
        -o-transition: transform 1s linear;
        transition: transform 1s linear;
      }
      .bottom {
        .name {
          color: #fff;
        }
        .count {
          color: #fff;
          .wemeet-renqi {
            color: #fff;
          }
        }
      }
    }
    .bottom {
      padding: 14px 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .name {
        color: #333333;
      }
      .count {
        color: #333333;
        .wemeet-renqi {
          color: #f28467;
        }
      }
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
}
</style>