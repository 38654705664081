<template>
  <div id="singleModule" style="height:580px;">
    <div class="moduleContainer aboutus">
      <ModuleTitle :title='title'></ModuleTitle>
      <div style="position:relative;">
        <el-carousel height="400px" :autoplay='autoPlay' :arrow='arrow' ref="carouselRef">
          <el-carousel-item v-for="(item, index) in imgkeyArr" :key="item.key">
            <div class="flexBox">
              <img :src="sitem" class="flexItem" alt="环境图片" v-for="(sitem, sindex) in item.value" :key="sindex" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <i class="iconfont wemeet-wangzhanxiangzuo" @click="handleClick('prev')"></i>
    <i class="iconfont wemeet-wangzhanxiangyou" @click="handleClick('next')"></i>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'companyDesc',
  components: {
    ModuleTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    imgkeyArr: {
      type: Array,
      default: '',
    },
  },
  data() {
    return {
      autoPlay: false,
      arrow: 'never',
    }
  },

  mounted() {},

  methods: {
    handleClick(e) {
      if (e == 'next') {
        this.$refs.carouselRef.next()
      } else {
        this.$refs.carouselRef.prev()
      }
    },
  },
}
</script>

<style lang='less'>
.aboutus .el-carousel__arrow {
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 1px solid #5abec1;
  transform: translate(-30%, -50%);
  i {
    font-size: 40px;
    color: #5abec1;
  }
}
</style>


<style lang="less" scoped>
.desc {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  p {
    margin-bottom: 20px;
  }
  .companyDoor {
    width: 100%;
    height: 400px;
    margin-top: 50px;
  }
}
.flexBox {
  display: grid;
  grid-template-columns: auto auto auto;
  .flexItem {
    width: 385px;
    height: 300px;
  }
}

.wemeet-wangzhanxiangzuo {
  font-size: 60px;
  color: #5abec1;
  position: absolute;
  top: 50%;
  left: 200px;
  transform: translateY(-50%);
  cursor: pointer;
}

.wemeet-wangzhanxiangyou {
  font-size: 60px;
  color: #5abec1;
  position: absolute;
  top: 50%;
  right: 220px;
  transform: translateY(-50%);
  z-index: 99;
  cursor: pointer;
}
</style>